@import "./styles/variables";

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  padding: 1rem;
}

html {
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: 0.75rem;
  transition: background-color 1.5s, color 1.5s;
}
input,
select,
body {
  font-family: "Sono";
}

.maintenanceLogo {
  max-width: 80vw;
  max-height: 40vh;
}
