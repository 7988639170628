.select {
  margin: 0.5rem;
  background: transparent;
  border: 1px solid var(--color-grid-line);
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  color: var(--color-text);
  text-transform: capitalize;
  min-width: 100px;
}

.h1 {
  padding: 1rem;
  font-size: 2rem;
  font-weight: bold;
  border-color: var(--color-grid-line);
}
