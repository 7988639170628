@import "../../styles/variables";

.component {
  width: 100%;
  max-width: 1000px;
  text-align: center;
}

.title {
  margin-bottom: 6vh;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  :global(.icon) {
    font-size: 5vh;
  }
}

.cost {
  color: var(--color-cost);
}

.price {
  color: var(--color-price);
}

.consumed {
  color: var(--color-consumption);
}
