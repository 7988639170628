@import "../../styles/_variables";
$width: 50px;
$height: $width / 3;
.settings {
  list-style: none;
  margin: $gap-lg 0;
  padding: 0;
  li {
    margin-bottom: $gap;
  }
}

.flipWrapper {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  border-radius: $height / 2;
  box-shadow: 0 0 1px 1px #999 inset;
  cursor: pointer;
  transition: background-image 0.12s, opacity 0.2s;
  background-image: linear-gradient(
    -90deg,
    $color-white 20%,
    $color-gray-dark 80%
  );
  opacity: 0.5;
  &.light {
    background-image: linear-gradient(
      -90deg,
      $color-white 0%,
      $color-gray-dark 50%
    );
  }
  &.dark {
    background-image: linear-gradient(
      -90deg,
      $color-white 50%,
      $color-gray-dark 100%
    );
  }
  &:hover {
    opacity: 0.95;
  }
}

.icon {
  font-size: $height / 2;
  position: absolute;
  width: $height;
  height: $height;
  z-index: 1;
  top: $height / 4;
}

.iconDark {
  left: 0;
  color: var(--color-white);
}

.iconLight {
  right: 0;
  color: var(--color-gray-dark);
}

.flipSwitch {
  position: absolute;
  top: 0;
  display: inline-block;
  width: $height - 1px;
  height: $height - 1px;
  border-radius: 50%;
  background-color: var(--color-gray-dark);
  box-shadow: 0 0 1px 1px var(--color-text) inset;
  box-sizing: border-box;
  transition: left 0.12s;
  left: calc(50% - #{$height / 2});
  z-index: 2;

  .light & {
    background-color: lighten($color-gray-dark, 20%);
    left: calc(100% - #{$height});
  }
  .dark & {
    left: 0;
  }
}
