@import "../../styles/variables";

.slice {
  text-align: left;
  color: var(--color-text);
  padding: $gap;
  border-radius: 3px;
  box-shadow: 0 0 3px var(--color-background-light) inset,
    0 0 3px var(--color-background);
  border: 1px solid var(--color-grid-line);
  background-color: var(--color-background-translucent);
}
.title {
  margin: 0 0 $gap-xs;
  text-align: center;
}
.sliceList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sliceItem {
  padding: $gap-xxs 0;
  display: grid;
  grid-template-columns: 1fr $gap-sm 1fr;
  gap: $gap-sm;
  align-items: center;
}
.sliceItemId {
  text-transform: capitalize;
  font-weight: bold;
  text-align: right;
}

.sliceIndicator {
  background-color: var(--item-color);
  width: $gap-sm;
  height: $gap-sm;
  border-radius: 2px;
}
