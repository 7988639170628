@import "../../styles/variables";

.AppHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 3rem;
}
