/**! DARK */
$color-white: #f0ece6;
$color-gray-dark: #282c34;
$color-yellow: #f3b641;
$color-green: #82ca9d;
$color-blue: #3c8196;

$color-text: $color-white;

$color-grid-line: rgba($color-white, 0.15);
$color-grid-tick: rgba($color-white, 0.65);
$color-grid-empty: rgba($color-white, 0.35);

$color-background: $color-gray-dark;
$color-background-light: lighten($color-gray-dark, 5%);
$color-background-translucent: rgba($color-gray-dark, 0.85);

$color-price: $color-blue;
$color-consumption: $color-green;
$color-cost: $color-yellow;

$color-spinner: $color-blue;

/**! LIGHT */
$theme-light-color-yellow: #d38e0d;
$theme-light-color-green: #44ca77;
$theme-light-color-blue: #3c8196;

$theme-light-color-text: $color-gray-dark;

$theme-light-color-grid-line: rgba($theme-light-color-text, 0.15);
$theme-light-color-grid-tick: rgba($theme-light-color-text, 0.65);
$theme-light-color-grid-empty: rgba($theme-light-color-text, 0.35);

$theme-light-color-background: $color-white;
$theme-light-color-background-light: darken($color-white, 5%);
$theme-light-color-background-translucent: rgba(
  $theme-light-color-background,
  0.85
);

$theme-light-color-price: $theme-light-color-blue;
$theme-light-color-consumption: $theme-light-color-green;
$theme-light-color-cost: $theme-light-color-yellow;

$theme-light-color-spinner: $theme-light-color-blue;

/**! GRID */

$gap: 1rem;
$gap-xxs: $gap * 0.25;
$gap-xs: $gap * 0.5;
$gap-sm: $gap * 0.75;
$gap-lg: $gap * 1.25;
$gap-xl: $gap * 1.5;
$gap-xxl: $gap * 2;
