@import "../../styles/variables";

.tooltip {
  background-color: var(--color-background-translucent);
  outline: thin 3px var(--color-background);
  color: var(--color-text);
  padding: $gap;
  border-radius: 3px;
  border: 1px solid var(--item-color);
  box-shadow: 0 0 3px var(--item-color) inset;
}
