@import "../../styles/variables";

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 6vh;
  width: 6vh;
  border-bottom: 2px solid $color-price;
  border-radius: 50%;
  opacity: 0.5;
}
@media (prefers-reduced-motion: no-preference) {
  .spinner {
    animation: spinner infinite 4s linear;
    box-shadow: 0px -2px 2px rgba($color-price, 0.5) inset,
      0 2px 2px rgba($color-price, 0.5);
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
